<template>
    <user-dashboard-layout>
        <v-container fluid class="fill-height p-5">
            <v-row>
                <v-col>
                    <v-card  class="mx-auto my-auto" >
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <avatar-edit
                                                v-model="user.avatar_url"
                                                @error="(m) => this.avatarErr = m"
                                                @file-added="avatarErr = null"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="avatarErr">
                                        <v-col>
                                            <v-alert type="error">
                                                {{ avatarErr }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field
                                                        v-model="user.first_name"
                                                        label="First name"
                                                        placeholder=""
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        v-model="user.last_name"
                                                        label="Last name"
                                                        placeholder=""
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="isFan">
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="user.fan.notification_consent"
                                                        label="I agree to a weekly newsletter"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn @click="handleUpdateProfile">Update</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "@/layouts/UserDashboardLayout";
    import User from "@/models/User";
    import Fan from "@/models/Fan";
    import AvatarEdit from "@/components/AvatarEdit";
    export default {
        name: "Profile",
        components: {AvatarEdit, UserDashboardLayout},
        computed: {
            isFan() {
                if (!this.user.roles) {
                    return false;
                }

                return this.user.roles.includes('fan')
            }
        },
        data: function () {
            return {
                user: {},
                avatarErr: null,
            }
        },
        methods: {
            async handleUpdateProfile() {
                const user = await new User(this.user).save();

                if (this.isFan) {
                    user.fan = await new Fan(this.user.fan).save()
                }

                this.user = user;
            }
        },
        mounted() {
            this.user = this.$auth.user()
        }
    }
</script>

<style scoped>

</style>
